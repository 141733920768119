<template>
    <div class="vw2">
        <div class="top-icon font12">
            <el-button @click="$router.go(-1)" type="warning" class="el-icon-arrow-left">
                {{ $t('message.Back') }}
            </el-button>
            <span>{{ $t('message.REFA History') }}</span>
            <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                {{ $t('message.Exit') }}
            </el-button>
        </div>
        <div class="my-tabs-card" v-loading="loadingData" :element-loading-text="$t('message.loading')" element-loading-spinner="el-icon-loading">
            <el-row :gutter="20">
                <el-col v-for="(item, index) in sale_check_times_history" :key="'sale_check_times_history-' + index" :span="8">
                    <div :class="getClassItem(item)">
                        <div class="itme-r-u">
                            <span>{{ $t('message.Working shift') }}:</span>
                            <b>{{ getWorkingShiftName(item) }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.sale') + ' №' }}:</span>
                            <b>{{ (item.sale_check_process && item.sale_check_process.sale_check) ? item.sale_check_process.sale_check.sale_id : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.product') }}:</span>
                            <b>{{ (item.sale_check_process && item.sale_check_process.sale_check && item.sale_check_process.sale_check.sale_product && item.sale_check_process.sale_check.sale_product.product) ? item.sale_check_process.sale_check.sale_product.product.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Process') }}:</span>
                            <b>{{ (item.sale_check_process && item.sale_check_process.sale_product_process && item.sale_check_process.sale_product_process.process) ? item.sale_check_process.sale_product_process.process.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Check') }}:</span>
                            <b>{{ (item.check_time) ? (item.check_time.name + ' (' + item.check_time.start_time + ' - ' + item.check_time.end_time + ')') : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Bant') }}:</span>
                            <b>{{ (item.sale_check_process && item.sale_check_process.bant) ? item.sale_check_process.bant.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Employee') }}:</span>
                            <b>{{ getEmployeeNames(item) }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Machine') }}:</span>
                            <b>{{ getMachineNames(item) }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Measurement time') }}:</span>
                            <b>{{ item.created_at }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.METO') + ' №' }}:</span>
                            <b>{{ getMetoNumbers(item) }}</b>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-show="!loadingData" :gutter="20">
                <el-col :span="24" class="text-center">
                    <el-button @click="incrementPaginationPage" :loading="loadingData" type="primary" icon="el-icon-arrow-down" round>{{ $t('message.Load more') }}</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import list from "@/utils/mixins/includes/list";

export default {
    mixins: [list],
    components: {},
    data() {
        return {
            
        };
    },
    computed: {
        ...mapGetters({            
            sale_check_times_history: 'saleChecks/sale_check_times_history',
            pagination: 'saleChecks/pagination',
        }),
    },
    methods: {
        ...mapActions({
            updateList: "saleChecks/getSaleCheckTimesHistory",
            updatePagination: "saleChecks/updatePagination",
            incrementPaginationPage: "saleChecks/incrementPaginationPage",            
        }),
        getClassItem(item) {
            let norm_per_hour = 0;
            if (item.sale_check_process && item.sale_check_process.sale_product_process) {
                // processni qancha vaqtda nechta chiqara olishi (minutda)
                let process_spent_time = item.sale_check_process.sale_product_process.process_spent_time;
                let production_count = item.sale_check_process.sale_product_process.production_count;

                // 1 soatda yani 60 minutda qancha tayorlash normasini topish
                norm_per_hour = (process_spent_time > 0) ? _.round(60 * production_count / process_spent_time) : 0;
            }

            let rating = 0;
            let rating_percent = (norm_per_hour > 0) ? (item.quantity_by_norm * 100 / norm_per_hour) : 0;
            if (rating_percent < 71) {
                rating = 3;
            }
            else if (rating_percent >= 72 && rating_percent < 86) {
                rating = 4;
            }
            else if (rating_percent >= 86) {
                rating = 5;
            }

            let class_name = 'rght-user-info-re ';

            switch(rating) {
                case 3:
                    class_name += 'bed';
                    break;
                case 4:
                    class_name += 'average';
                    break;
                case 5:
                    class_name += 'good';
                    break;
            }

            return class_name;
        },
        getWorkingShiftName(item) {
            let working_shift = (item.sale_check_process && item.sale_check_process.sale_check && item.sale_check_process.sale_check.working_shift) ? item.sale_check_process.sale_check.working_shift : null;

            if (working_shift) {
                return (working_shift.name + ' (' + working_shift.start_time + ' - ' + working_shift.end_time + ')');
            }
            else {
                return ''
            }
        },
        getEmployeeNames(item) {
            var result = '';
            _.forEach(item.sale_check_employees, function(item2, key) {
                let employee_name = (item2.employee) ? item2.employee.name : '';
                if (key === 0) {
                    result += employee_name;
                }
                else {
                    result += (', ' + employee_name);
                }
            });

            return result;
        },
        getMachineNames(item) {
            var result = '';
            _.forEach(item.sale_check_employees, function(item2, key) {
                let machine_name = (item2.machine) ? (item2.machine.name + ' (' + item2.machine.code + ')') : '';
                if (key === 0) {
                    result += machine_name;
                }
                else {
                    result += (', ' + machine_name);
                }
            });

            return result;
        },
        getMetoNumbers(item) {
            var result = '';
            _.forEach(item.meto_numbers, function(item2, key) {
                if (key === 0) {
                    result += item2.current_meto_number;
                }
                else {
                    result += (', ' + item2.current_meto_number);
                }
            });

            return result;
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('saleChecks/EMPTY_SET_SALE_CHECK_TIMES_HISTORY');
        next()
    },
};
</script>